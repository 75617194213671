import React from "react";
import { graphql, Link } from 'gatsby';
import Img from "gatsby-image";
import Layout from "../layouts/layout";
import { renderEmdAssetBlock } from "../utils";
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { BLOCKS } from '@contentful/rich-text-types';

const NewsDetail = ({ data: { hero, pageData, allContentfulNews } }) => {

    hero.buttons = (
        <div className="mt-4">
            <div className="inline md:inline-block uppercase text-sm text-white">{pageData.heroHeadingTwo}</div>
        </div>
    )

    const options = {
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node, next) => `<p>${next(node.content)}</p>`,
            [BLOCKS.EMBEDDED_ASSET]: (node) => {

                const ref = renderEmdAssetBlock(pageData.body.references, node);
                return (
                    `<img className="object-cover object-center w-full h-32 block" alt="" src=${ref.fluid.src} />`
                );
            }
        }
    };

    return (

        <Layout hero={hero}>
            <div className="container mx-auto mb-16 sm:mb-32">
                <div className="sm:flex items-center">

                    <div className="px-8 mb-4 md:w-7/12 sm:mb-0 self-start">
                        <h1 className="text-1-5 font-aktiv font-bold dark-blue leading-tight mb-4">{pageData.title}</h1>
                        <p className="font-aktiv font-bold dark-blue leading-tight mb-4">{pageData.location}</p> 
                         <div className="nested-html whitespace-pre-wrap mx-auto text-justify"
                              dangerouslySetInnerHTML={{ __html: documentToHtmlString(JSON.parse(pageData.body.raw), options) }}
                        />
                    </div>
                    
                    {/* sidebar of recent news - see index  */}
                    <div className="px-8 mb-4 md:w-5/12 sm:mb-0 px-4 self-start sm:ml-2">

                    <h2 className="text-1-5 font-aktiv font-bold dark-blue leading-tight mb-4">Recent News</h2>
                    {allContentfulNews.edges.map(({node}) => (
                        <div className="lg:flex items-start mb-4 sm:mb-12" key={node.id}>
                            <div className="mr-6 w-full lg:w-1/2">
                                <Link to={`/news/${node.slug}`}>
                                    {node.picture && node.picture.fluid &&
                                    <Img className="object-cover object-center w-full h-32 block" alt="" fluid={node.picture.fluid} />
                                    }
                                </Link>
                            </div>
                            <div className="flex-col w-full lg:w-1/2">
                                <div className="inline md:inline-block uppercase text-xs dark-blue font-bold font-aktivcd"><Link to={`/news/${node.slug}`}>Project</Link></div>
                                <span className="square"></span>
                                <span className="text-xs">{node.date}</span>
                                <Link to={`/news/${node.slug}`}>
                                    <h4 className="font-bold text-sm md:text-base mt-3">{node.title}</h4>
                                </Link>
                            </div>
                        </div>
                    ))}
                    </div>


                </div>
            </div>

        </Layout>

    )};

export const query = graphql`
    query CaseStudyQuery($slug: String!) {
      hero: contentfulNews(slug: { eq: $slug })  {
        heroBackgroundImage {
          resize(width: 1440) {
           src
          }
        }
        title
        heroHeading: title
      }
      pageData: contentfulNews (slug: { eq: $slug}) {
           body { 
                raw
                references {
                ... on ContentfulAsset {
                        __typename
                        contentful_id
                        fluid {
                            ...GatsbyContentfulFluid_withWebp_noBase64
                        }
                    }
                }
          }
          heroHeadingTwo: date(formatString: "MMMM YYYY")
          location
          picture { 
            fluid {
                ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
       }
      allContentfulNews(limit: 4) {
            edges {
              node {
                slug
                id
                title
                date (formatString: "MMMM DD, YYYY")
                picture {
                   fluid (maxWidth:800, quality:60) {
                      ...GatsbyContentfulFluid_withWebp_noBase64
                  }
                }
            }
         }
      }
    }
`;


export default NewsDetail;
